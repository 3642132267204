import React from "react"
import { useSpring, animated } from "react-spring"

function Arc(props) {
  const arc = useSpring({
    delay: 200,
    config: { clamp: true, friction: 50, tension: 100 },
    x: props.parsedValue * 245 * 2,
    from: { x: props.parsedValue * 245 },
  })

  const hand = useSpring({
    delay: 200,
    config: { clamp: true, friction: 51, tension: 100 },
    transform: `rotate(${props.transformTo}deg)`,
    from: { transform: `rotate(216deg)` },
  })

  return (
    <>
      <animated.svg
        width={400}
        height={400}
        className={props.className}
        strokeDashoffset={arc.x}
        strokeDasharray={props.parsedValue * 245}
      >
        <path
          d={props.data}
          fillOpacity={"0"}
          stroke="#A3A3A3"
          strokeWidth={10}
        />
      </animated.svg>
      <animated.div
        className="Kardometer__hand"
        style={{ transform: hand.transform }}
      />
    </>
  )
}

export default Arc
