import React from "react"
import { useSpring, animated } from "react-spring"

function Arc(props) {
  const value = useSpring({
    delay: 200,
    config: { clamp: true, friction: 50, tension: 100 },
    number: props.parsedValue,
    from: { number: 0 },
  })

  return (
    <div className="Kardometer__gauge">
      <h2 className="Kardometer__gauge__l1">TYPE</h2>
      <animated.h1 className="Kardometer__gauge__value">
        {value.number.interpolate(v => {
          return parseFloat(v.toFixed(2))
        })}
      </animated.h1>
      <h2 className="Kardometer__gauge__l2">CIVILIZATION</h2>
    </div>
  )
}

export default Arc
