import { Link } from "gatsby"
import React from "react"

import "./selector.scss"

const Selector = (props) => (
  <div className="Selector">
    <Link to="/select" className="Selector__change">
      <p className="Selector__change__text">CHANGE SELECTION</p>
    </Link>
    <div className="Selector__selection">
        <span className="Selector__selection__label">VIEWING CIVILIZATION</span>
        <span className="Selector__selection__name">{props.civilisation}</span>
    </div>
  </div>
)

export default Selector
