import React from "react"
import "./kardometer.scss"
import Marks from "../../images/kardometer.svg"
import { useSpring, animated } from "react-spring"
import Arc from "../Arc/arc"
import Gauge from "../Gauge/gauge"

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function makePath(x, y, radius, startAngle, endAngle) {
  // Filter 360 vals or render is blank.
  if (endAngle === 360) endAngle = 359.9

  var start = polarToCartesian(x, y, radius, endAngle)
  var end = polarToCartesian(x, y, radius, startAngle)
  var largeArcFlag = "0"
  if (endAngle >= startAngle) {
    largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
  } else {
    largeArcFlag = endAngle + 360.0 - startAngle <= 180 ? "0" : "1"
  }

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ")
  return d
}

function angleFromValue(val) {
  // Calculate output angle on dial for input value
  return 216 + (288 * val) / 4
}

function Kardometer(props) {
  // Poor named I know, parsedValue = kardashev scale value of the civilization
  const parsedValue = props.value > 4 ? 4 : props.value

  return (
    <div className="Kardometer">
      <div className="Kardometer__dial" />
      <img src={Marks} className="Kardometer__marks" />
      <Arc
        parsedValue={parsedValue}
        data={makePath(200, 200, 195, 216, angleFromValue(parsedValue))}
        transformTo={angleFromValue(parsedValue)}
        className="Kardometer__arc"
      />
      <Gauge parsedValue={parsedValue} />
    </div>
  )
}

export default Kardometer
