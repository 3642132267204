import React from "react"
import SEO from "../components/seo"
import Layout from "../components/Layout/layout"
import Selector from "../components/Selector/selector"
import Kardometer from "../components/Kardometer/kardometer"

const IndexPage = () => (
  <Layout>
    <SEO title="Kardometer" />
    <Selector civilisation="Humanity" />
    <Kardometer value={0.73} />
  </Layout>
)

export default IndexPage
